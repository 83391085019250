<template>
  <nav class="level is-mobile mx-4">
    <div class="level-left">
      <div class="level-item">
        <b-button
          icon-left="chevron-left"
          rounded
          size="is-small"
          type="is-primary is-light"
          tag="router-link"
          :to="prevTo"
        ></b-button>
      </div>
    </div>
    <div class="level-item">
      <slot></slot>
    </div>
    <div class="level-right">
      <div class="level-item">
        <b-button
          icon-left="chevron-right"
          rounded
          size="is-small"
          type="is-primary is-light"
          tag="router-link"
          :to="nextTo"
          :disabled="nextDisabled"
        ></b-button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "PeriodNavBar",
  props: {
    prevTo: [String, Object],
    nextTo: [String, Object],
    nextDisabled: Boolean,
  },
};
</script>
