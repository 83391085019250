<template>
  <div class="container is-max-desktop">
    <period-nav-bar
      :prevTo="prevTo"
      :nextTo="nextTo"
      :nextDisabled="nextYearMonth === undefined"
    >
      <b-icon :icon="isClosed ? 'lock' : 'lock-open-variant'"></b-icon>
      <span class="pl-2">{{ targetYearMonth }}</span>
    </period-nav-bar>

    <div class="m-4" v-if="!isClosed && !isForAdmin">
      <b-field label="日付" :type="hasError('date')" :message="message('date')">
        <b-datepicker
          icon="calendar"
          locale="ja"
          :min-date="minDate"
          :max-date="maxDate"
          required
          v-model="addForm.date"
        ></b-datepicker>
      </b-field>
      <b-field
        label="勘定科目"
        :type="hasError('accounts_code')"
        :message="message('accounts_code')"
      >
        <b-select
          icon="bank"
          value=""
          required
          expanded
          v-model="addForm.accounts_code"
        >
          <option
            :value="code.value"
            v-for="code in accountCodes"
            :key="code.value"
          >
            {{ code.label }}
          </option>
        </b-select>
      </b-field>
      <b-field label="摘要" :type="hasError('note')" :message="message('note')">
        <b-input
          type="text"
          icon="text"
          required
          v-model="addForm.note"
        ></b-input>
      </b-field>
      <b-field
        label="金額"
        :type="hasError('amount')"
        :message="message('amount')"
      >
        <b-input
          type="number"
          icon="cash-100"
          v-model.number="addForm.amount"
          expanded
          required
          min="1"
        ></b-input>
        <p class="control">
          <span class="button is-static">円</span>
        </p>
      </b-field>
      <b-button
        type="is-primary"
        icon-left="cash-plus"
        expanded
        @click="addAdvance"
        :loading="storing"
        >登録</b-button
      >
    </div>
    <b-table
      ref="advances"
      striped
      narrowed
      :mobile-cards="false"
      :data="advances"
      :selected.sync="selected"
      detailed
      custom-detail-row
      detail-key="id"
      :show-detail-icon="false"
      @select="handleRowSelect"
      class="advances"
      :loading="loading"
    >
      <b-table-column field="date" label="日" v-slot="props">
        <b-field v-if="isRowSelected(props)">
          <b-datepicker
            icon="calendar"
            locale="ja"
            :min-date="minDate"
            :max-date="maxDate"
            required
            v-model="editForm.date"
            :mobile-native="false"
          >
            <template v-slot:trigger>
              <span
                class="is-clickable is-size-6 has-text-primary is-underlined"
                >{{ displayDate(editForm.date) }}</span
              >
            </template>
          </b-datepicker>
        </b-field>

        <span v-else>{{ displayDate(props.row.date) }}</span>
      </b-table-column>
      <b-table-column field="dayOfWeek" label="曜日" v-slot="props">
        {{ displayDayOfWeek(props.row.date) }}
      </b-table-column>
      <b-table-column field="account" label="勘定科目/摘要" v-slot="props">
        <div v-if="isRowSelected(props)">
          <b-field>
            <b-select
              value=""
              required
              expanded
              v-model="editForm.accounts_code"
              size="is-small"
            >
              <option
                :value="code.value"
                v-for="code in accountCodes"
                :key="code.value"
              >
                {{ code.label }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-else>
          {{ displayAccountCode(props.row.accounts_code) }}
          <br />
          {{ props.row.note }}
        </div>
      </b-table-column>
      <b-table-column
        field="amount"
        label="金額"
        v-slot="props"
        numeric
        width="7.5rem"
      >
        <b-field v-if="isRowSelected(props)">
          <b-input
            type="number"
            v-model.number="editForm.amount"
            expanded
            required
            min="1"
            size="is-small"
          ></b-input>
          <p class="control">
            <span class="button is-static is-small">円</span>
          </p>
        </b-field>
        <span v-else> {{ props.row.amount }} 円</span>
      </b-table-column>
      <template #detail="props">
        <tr class="detail">
          <td>
            <b-button
              size="is-small"
              type="is-danger"
              icon-left="delete"
              @click="removeAdvance(props.row)"
            >
            </b-button>
          </td>
          <td colspan="2">
            <b-field>
              <b-input
                type="text"
                required
                v-model="editForm.note"
                size="is-small"
              ></b-input>
            </b-field>
          </td>
          <td class="buttons">
            <b-button
              size="is-small"
              type="is-primary is-light"
              outlined
              icon-left="close"
              @click="closeDetail(props.row)"
            >
            </b-button
            ><b-button
              size="is-small"
              type="is-primary"
              icon-left="check"
              @click="saveAdvance(props.row)"
            >
              保存
            </b-button>
          </td>
        </tr>
      </template>
      <template #footer>
        <div class="has-text-right">合計: {{ totalAmount }}円</div>
      </template>
    </b-table>

    <div class="m-4" v-if="!isClosed && !isForAdmin">
      <b-button
        icon-left="lock"
        type="is-warning"
        expanded
        :loading="storing"
        @click="closeSheet"
        >締め</b-button
      >
    </div>

    <div class="m-4" v-if="isClosed && isForAdmin">
      <b-button
        icon-left="lock-open-variant"
        type="is-warning"
        expanded
        :loading="storing"
        @click="cancelSheet"
        >締め解除</b-button
      >
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import fetchMixin from "../fetch-mixin";
import storeMixin, { clone, formatDateField } from "../store-mixin";
import { ACCOUNTS_CODE } from "../constants";
import PeriodNavBar from "../components/PeriodNavBar";
import store from "../store";

const initialAddForm = () => ({
  date: null,
  accounts_code: null,
  note: null,
  amount: null,
});

export default {
  name: "AdvanceSheet",
  mixins: [storeMixin, fetchMixin],
  components: { PeriodNavBar },
  data() {
    return {
      loading: false,
      selected: null,
      addForm: initialAddForm(),
      editForm: {
        id: null,
        date: null,
        accounts_code: null,
        note: null,
        amount: null,
      },
    };
  },
  props: {
    userId: {
      type: [String, Number],
    },
    year: {
      type: [String, Number],
      default: () => dayjs().get("year"),
    },
    month: {
      type: [String, Number],
      default: () => dayjs().get("month") + 1,
    },
  },
  computed: {
    period() {
      return dayjs({ year: this.year, month: this.month - 1 });
    },
    accountCodes() {
      return Object.values(ACCOUNTS_CODE);
    },
    targetYearMonth() {
      return `${this.year}年${this.month}月`;
    },
    prevYearMonth() {
      const date = dayjs({ year: this.year, month: this.month - 1 }).subtract(
        1,
        "month"
      );
      return { year: date.get("year"), month: date.get("month") + 1 };
    },
    nextYearMonth() {
      if (this.period.isSame(dayjs(), "month")) {
        return undefined;
      }
      const date = this.period.add(1, "month");
      return { year: date.get("year"), month: date.get("month") + 1 };
    },
    minDate() {
      return this.period.set(1, "day").toDate();
    },
    maxDate() {
      return this.period.endOf("month").toDate();
    },
    sheet() {
      const period = this.period.format("YYYYMM");
      if (this.isForAdmin) {
        const userIndex = this.$store.state.admin.users.findIndex(
          (v) => +v.id === +this.userId
        );
        if (userIndex === -1) {
          return undefined;
        }
        const index = this.$store.state.admin.users[
          userIndex
        ].advance_sheets.findIndex(
          (v) => v.period_year + v.period_month === period
        );
        return this.$store.state.admin.users[userIndex].advance_sheets[index];
      } else {
        return this.$store.state.my.advance_sheets[period];
      }
    },
    isClosed() {
      return this.sheet === undefined ? false : this.sheet.closed_at !== null;
    },
    advances() {
      return this.sheet?.advances ?? [];
    },
    totalAmount() {
      return this.advances.reduce((a, c) => a + c.amount, 0) ?? 0;
    },
    isForAdmin() {
      return this.userId !== undefined;
    },
    nextTo() {
      return this.isForAdmin
        ? {
            name: "adminAdvanceSheet",
            params: { userId: this.userId, ...this.nextYearMonth },
          }
        : { name: "advanceSheet", params: this.nextYearMonth };
    },
    prevTo() {
      return this.isForAdmin
        ? {
            name: "adminAdvanceSheet",
            params: { userId: this.userId, ...this.prevYearMonth },
          }
        : { name: "advanceSheet", params: this.prevYearMonth };
    },
  },
  methods: {
    displayDate(date) {
      return dayjs(date).format("D");
    },
    displayDayOfWeek(date) {
      return dayjs(date).format("dd");
    },
    displayAccountCode(accountCode) {
      return this.accountCodes.find((v) => +v.value === +accountCode).label;
    },
    isRowSelected(props) {
      return (
        !this.isClosed && !this.isForAdmin && this.selected?.id === props.row.id
      );
    },
    handleRowSelect(row, oldRow) {
      if (this.isClosed || this.isForAdmin) {
        return;
      }
      this.editForm = {
        id: row.id,
        date: dayjs(row.date).toDate(),
        accounts_code: row.accounts_code,
        note: row.note,
        amount: row.amount,
      };
      if (oldRow !== null) {
        this.$refs.advances.closeDetailRow(oldRow);
      }
      this.$refs.advances.openDetailRow(row);
    },
    closeDetail(row) {
      this.$refs.advances.closeDetailRow(row);
      this.selected = null;
    },
    async fetchData() {
      try {
        this.fetch(async () => {
          if (this.sheet !== undefined) {
            if (this.isForAdmin) {
              await this.$store.dispatch("admin/getAdvanceSheet", {
                userId: this.userId,
                advanceSheetId: this.sheet.id,
              });
            } else {
              await this.$store.dispatch("my/getAdvanceSheet", this.sheet.id);
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async addAdvance() {
      try {
        const form = clone(this.addForm);
        form.date = formatDateField(form.date);
        await this.store("my/addAdvance", {
          period: this.period.format("YYYYMM"),
          requestBody: form,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async saveAdvance(row) {
      try {
        const { id, ...form } = clone(this.editForm);
        form.date = formatDateField(form.date);
        await this.store("my/updateAdvance", {
          period: this.period.format("YYYYMM"),
          advanceId: id,
          requestBody: form,
        });
        this.closeDetail(row);
      } catch (e) {
        console.log(e);
      }
    },
    async removeAdvance(row) {
      try {
        await this.store("my/removeAdvance", {
          period: this.period.format("YYYYMM"),
          advanceId: row.id,
        });
        this.closeDetail(row);
      } catch (e) {
        console.log(e);
      }
    },
    async closeSheet() {
      this.$buefy.dialog.confirm({
        title: "注意",
        message: `${this.targetYearMonth}の立替金申請を締めます。<br>締め状態を解除したい場合は管理者に連絡してください。`,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.closeDetail(this.selected);
            await this.store(
              "my/closeAdvanceSheet",
              this.period.format("YYYYMM")
            );
            this.addForm = initialAddForm();
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    async cancelSheet() {
      this.$buefy.dialog.confirm({
        title: "注意",
        message: `${this.targetYearMonth}の立替金申請の締め状態を解除します。`,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.store("admin/cancelAdvanceSheetClosing", {
              userId: this.userId,
              advanceSheetId: this.sheet.id,
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  },
  watch: {
    $route: "fetchData",
  },
  async beforeRouteEnter(route, _redirect, next) {
    store.commit("setLoading", true);
    try {
      if (route.name === "adminAdvanceSheet") {
        const period =
          route.params.year.toString() +
          route.params.month.toString().padStart(2, "0");
        const userId = route.params.userId;
        await store.dispatch("admin/enterAdvanceSheet", { period, userId });
      } else {
        const period =
          route.name === "latestAdvanceSheet"
            ? dayjs().format("YYYYMM")
            : route.params.year.toString() +
              route.params.month.toString().padStart(2, "0");
        await store.dispatch("my/enterAdvanceSheet", { period });
      }
      store.commit("setLoading", false);
      next();
    } catch (e) {
      console.log("AdvanceSheet");
      console.log(e);
      store.commit("setError", e.message);
      store.commit("setLoading", false);
      if (e.status && e.status === 401) {
        next({ name: "login" });
      } else {
        next(false);
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.addForm = initialAddForm();
    next();
  },
};
</script>

<style>
.b-table.advances .table.is-striped tr.is-selected,
.b-table.advances .table.is-striped tr.detail {
  background-color: #f2effb;
  color: #363636;
}

.b-table.advances .table.is-striped tr.is-selected td {
  border-color: transparent;
}

.is-underlined {
  text-decoration: underline;
}
</style>
